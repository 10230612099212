import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { ButtonGhost, ButtonRoundedIconSlider } from '/features/buildingBlocks/Button'
import { useContrastingStyle } from '/machinery/StyleContext'
import { HeadingXl, HeadingSm, HeadingXs } from '/features/buildingBlocks/Heading'
import { TextMd } from '/features/buildingBlocks/Text'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useLocationMatch } from '@kaliber/routing'
import { useKeenSlider } from 'keen-slider/react'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

import mediaStyles from '/cssGlobal/media.css'
import styles from './InspirationalContent.css'

import fallback from '/images/temp/fallback.png'
import iconChevronRight from '/images/icons/chevron-right-boxed.raw.svg'
import iconChevronLeft from '/images/icons/chevron-left-boxed.raw.svg'

export function InspirationalContent({ inspirationalContent, dataX = undefined, layoutClassName = undefined }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const contrastingStyleContext = useContrastingStyle()
  const { params: { language } } = useLocationMatch()
  const { __ } = useTranslate()

  const { title, description } = inspirationalContent
  const items = inspirationalContent.items || []

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'snap',
    slides: {
      origin: 0,
      perView: isViewportMd ? 'auto' : 1.2,
      spacing: isViewportMd ? 24 : 15,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    dragChecked(slider) {
      trackInteraction({
        title: title ?? 'inspirational-content',
        action: 'vertical scrolled',
        type: 'slided',
        index: slider.track.details.rel
      })
    },
    selector: x => x.childNodes,
  })

  React.useEffect(
    () => instanceRef.current.update(),
    [instanceRef, isViewportMd]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ButtonRoundedIconSlider
        onClick={() => instanceRef.current?.prev()}
        ariaLabel={__({ x: __`card` })`previous-x`}
        icon={iconChevronLeft}
        dataX='go-to-previous-card'
        layoutClassName={cx(styles.iconLeftLayout, currentSlide === 0 && styles.isHidden)}
      />

      <div className={cx(styles.content, currentSlide >= 1 && styles.slideOne, currentSlide >= 2 && styles.slideTwo)}>
        {title && <HeadingXl h='2' {...{ title }} layoutClassName={styles.headingLayout} />}
        {description && <TextMd text={description} layoutClassName={styles.subHeadingLayout} />}
      </div>

      <div data-style-context={contrastingStyleContext} className={styles.sliderContainer}>
        <div className={styles.slider} ref={sliderRef}>
          {items.map((x, i) => (
            <Card
              key={x._key}
              image={getImage(x)}
              title={x.titleOverwrite || x.title || x.ref?.title}
              label={__`component-InspirationalContent-read-more`}
              posterSizeWidth={isViewportMd ? 2.2 : 1.2}
              layoutClassName={styles.cardLayout}
              link={getLink(x, language)}
              {...{ dataX }}
            />
          ))}
        </div>
      </div>

      <ButtonRoundedIconSlider
        onClick={() => instanceRef.current?.next()}
        ariaLabel={__({ x: __`card` })`next-x`}
        icon={iconChevronRight}
        dataX='go-to-next-card'
        layoutClassName={cx(styles.iconRightLayout, (currentSlide === (items.length - 1)) && styles.isHidden)}
      />
    </div>
  )
}

function Card({ image, title, link, label, posterSizeWidth, dataX = undefined, layoutClassName = undefined }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false

  const Heading = isMobile
    ? HeadingXs
    : HeadingSm

  const sizes = `
    (min-width: ${mediaStyles.breakpointXl}) calc(var(--container-max-width-xl) / ${posterSizeWidth}),
    (min-width: ${mediaStyles.breakpointLg}) calc(var(--container-max-width-lg) / ${posterSizeWidth}),
    (min-width: ${mediaStyles.breakpointMd}) calc(var(--container-max-width-md) / ${posterSizeWidth}),
    (min-width: ${mediaStyles.breakpointSm}) calc(var(--container-max-width-sm) / ${posterSizeWidth}),
    calc(100cqw / ${posterSizeWidth})
  `

  return (
    <div className={cx(styles.componentCard, layoutClassName)}>
      <div className={styles.coverImage}>
        {image
          ? <ImageCover aspectRatio={1 / 1} layoutClassName={styles.imageLayout} {...{ image, sizes }} />
          : <ImageFallback layoutClassName={styles.imageLayout} {...{ sizes }} />
        }
      </div>

      <Heading h='3' {...{ title }} layoutClassName={styles.headingLayout} />

      {link && (
        <ButtonGhost
          dataX={dataX || 'link'}
          layoutClassName={styles.buttonLayout}
          href={link}
        >
          {label}
        </ButtonGhost>
      )}
    </div>
  )
}

function ImageFallback({ sizes, layoutClassName }) {
  return <img className={layoutClassName} src={fallback} role='presentation' alt='' {...{ sizes }} />
}

function getLink(item, language) {
  if (item?.ref) return determineDocumentPathSyncWithParams({ document: item.ref, routeMap, params: item.params })
  if (item?.slug) return routeMap.app.lifeAtRituals.article({ language, slug: item.slug.current })
  return undefined
}

function getImage(item) {
  return (
    item.imageOverwrite?.asset ? item.imageOverwrite :
    item.image?.asset ? item.image :
    item.ref?.image?.asset ? item.ref.image :
    item.ref?.heroTypeImage?.asset ? item.ref.heroTypeImage :
    item.ref?.relatedContentImage?.asset ? item.ref.relatedContentImage :
    null
  )
}
